export function validateEmptyAndLength6 (value) {
  if (!value) {
    return '*Campo Obrigatório'
  }

  if (value.length < 6) {
    return '*Mínimo de 6 caracteres'
  }

  return true
}

export function validateEmptyAndLength3 (value) {
  if (!value) {
    return '*Campo Obrigatório'
  }

  if (value.length < 3) {
    return '*Mínimo de 3 caracteres'
  }

  return true
}

export function validateCpf (value) {
  // precisa validar o CPF
  return true
}
