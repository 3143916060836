<template>
  <div class="cadastro">
    <Voltar />
    <h1 class="titulo">Bem vindo</h1>
    <form class="form" @submit.prevent="handleSubmit">
      <div>
        <label for="cpf">CPF</label>
        <input
          type="text"
          v-model="state.cpf.value"
          maxlength="14"
          :class="{
            'borda-de-erro': !!state.cpf.errorMessage,
          }"
        />
        <span v-if="!!state.cpf.errorMessage">
          {{ state.cpf.errorMessage }}
        </span>
      </div>
      <div>
        <label for="senha">SENHA</label>
        <input
          type="password"
          v-model="state.senha.value"
          maxlength="100"
          :class="{
            'borda-de-erro': !!state.senha.errorMessage,
          }"
        />
        <span v-if="!!state.senha.errorMessage">
          {{ state.senha.errorMessage }}
        </span>
        <p><a href="/esqueci-senha">Esqueceu seus dados de acesso?</a></p>
      </div>
      <div class="bt">
        <button type="submit" :disabled="state.isLoading">Entrar</button>
      </div>
      <div class="bt bt--discreto">
        <a href="/cadastro">Não tem conta? CLIQUE AQUI E CADASTRE-SE</a>
      </div>
    </form>
  </div>
</template>
<script>
import { reactive } from 'vue'
// import { useRouter } from 'vue-router'
import { useField } from 'vee-validate'
import { useToast } from 'vue-toastification'
import { validateEmptyAndLength6, validateCpf } from '../../utils/validators'
import services from '../../services'
import Voltar from '../../components/Voltar'

export default {
  setup () {
    // const router = useRouter()
    const toast = useToast()
    const { value: cpfValue, errorMessage: cpfErrorMessage } = useField(
      'cpf',
      validateCpf
    )

    const { value: senhaValue, errorMessage: senhaErrorMessage } = useField(
      'senha',
      validateEmptyAndLength6
    )

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      cpf: {
        value: cpfValue,
        errorMessage: cpfErrorMessage
      },
      senha: {
        value: senhaValue,
        errorMessage: senhaErrorMessage
      }
    })

    async function handleSubmit () {
      toast.clear()
      try {
        state.isLoading = true
        const { data, errors } = await services.login.login({
          cpf: state.cpf.value,
          senha: state.senha.value
        })

        if (!errors) {
          window.localStorage.setItem('user', JSON.stringify(data))
          // router.push({ name: 'HomeLogado' })
          if (data.confirmacao_sms !== null) {
            window.location = '/home'
          } else {
            window.location = '/cadastro-sms'
          }
          state.isLoading = false
          return
        }

        if (errors.status === 404) {
          toast.error('CPF e/ou Senha não encontrado')
        }

        if (errors.status === 401) {
          toast.error('CPF e/ou Senha Inválido')
        }

        if (errors.status === 400) {
          toast.error('Erro ao fazer o Login!')
        }

        if (errors.status === 422) {
          toast.error(errors.statusText)
        }
        state.isLoading = false
      } catch (error) {
        toast.error('Erro ao fazer o Login! - 1')
        state.isLoading = false
        state.hasErrors = !!error
      }
    }

    return {
      state,
      handleSubmit
    }
  },
  components: { Voltar }
}
</script>
